
import {
  ApiHelper,
  ApiService,
  boolToString,
  ButtonComponent,
  dateFormat,
  Dictionary,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  FormInfoDataDto,
  FormReadDto,
  getStatusReportName,
  HelpHelper,
  Logger,
  NotificationsService,
  RepositoryReadDto,
  TABLE_ICONS,
  TableComponent,
  TableDataSet,
  TableModel,
  TableMultiHeaders,
  TableRow,
} from "table";
import { defineComponent, PropType } from "vue";
import { ReportDto } from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import ApiActionsService from "@/services/ApiActions/ApiActionsService";
import EditorFormsNavigation from "@/modules/editor-forms/components/EditorFormsNavigation.vue";
import { AttrsDataSet } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDataSet";
import { ReportFilter } from "@/modules/registration-reports/common/types";
import ReportFormContextMenu from "@/modules/editor-forms/components/ReportFormContextMenu/ReportFormContextMenu.vue";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import ReportFormAttrs from "@/modules/editor-forms/components/ReportFormAttrs/ReportFormAttrs.vue";
import { TableCellPointer } from "table/dist/components/TableComponent/common/types/TableCellPointer";
import { reportHelpers } from "@/modules/registration-reports/common/helpers";
import { ApiVocUserDto } from "@/services/AppUsersService/types";
import { RepositoryExtensionDto } from "@/services/AppApiRepositoryService/types/RepositoryExtensionDto";
import {
  APP_ICONS,
  DEFAULT_LEFT_COLUMN_NAME,
  HEADER_IDS,
} from "@/common/consts";
import FillByAnalyticalReportModal, {
  FillByAnalyticalReportProps,
} from "@/modules/editor-forms/components/FillByAnalyticalReportModal/FillByAnalyticalReportModal.vue";
import { ReloadFormReadParams } from "@/modules/editor-forms/common/types";
import {
  ActionReportForms,
  ActionsExecReportFormsContext,
  ReportFormActionsDict,
} from "@/services/ApiActions/types";
import RepositoryExtensionModal, {
  RepositoryExtensionProps,
} from "@/modules/editor-forms/components/RepositoryExtensionModal/RepositoryExtensionModal.vue";
import SystemAttrsComponent from "@/modules/editor-forms/components/SystemAttrsComponent/SystemAttrsComponent.vue";
import { getRouteLocationRegReportsByFilter } from "@/router/helpers";
import ExportManagerInfo from "table/dist/types/ExportManagerInfo";
import { UserHelper } from "@/common/helpers/UserHelper";
import AppApiService from "@/services/AppApiService/AppApiService";
import AppUsersService from "@/services/AppUsersService/AppUsersService";
import { HelpHelperTableContext } from "table/dist/classes/HelpHelper";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";
import { TableAppData } from "@/common/types/TableAppData";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";
import ReportingFormBottom from "@/modules/editor-forms/components/ReportingForm/ReportingFormBottom.vue";
import TableRemarksModal, {
  TableRemarksProps,
} from "@/modules/editor-forms/components/TableRemarksModal/TableRemarksModal.vue";
import ReportingFormToolbar from "@/modules/editor-forms/components/ReportingForm/ReportingFormToolbar.vue";
import HeaderSaveIndicator from "@/components/HeaderSaveIndicator.vue";
import CalculatorsModals from "@/components/smart/CalculatorsModals.vue";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";

export default defineComponent({
  name: "ReportingForm",
  components: {
    EditableHelpComponent,
    CreateVocEntryButton,
    CalculatorsModals,
    TableRemarksModal,
    ReportingFormBottom,
    EnterModalComponent,
    SystemAttrsComponent,
    TableMultiHeaders,
    TableComponent,
    ReportFormAttrs,
    ButtonComponent,
    EditorFormsNavigation,
    ReportFormContextMenu,
    FillByAnalyticalReportModal,
    RepositoryExtensionModal,
    ReportingFormToolbar,
    HeaderSaveIndicator,
  },
  props: {
    report: {
      type: Object as PropType<ReportDto | undefined>,
      required: false,
    },
    formRead: {
      type: Object as PropType<FormReadDto>,
      required: true,
    },
    formRepositoryRead: {
      type: Object as PropType<RepositoryReadDto>,
      required: true,
    },
    repositoryExtensions: {
      type: Object as PropType<RepositoryExtensionDto[] | null>,
      required: true,
    },
    tables: {
      type: Object as PropType<Dictionary<TableAppData>>,
      required: true,
    },
    attrsDataSet: {
      type: Object as PropType<AttrsDataSet>,
      required: true,
    },
    helpHelper: {
      type: Object as PropType<HelpHelper<HelpHelperTableContext>>,
      required: true,
    },
    formActions: {
      type: Object as PropType<ReportFormActionsDict>,
    },
    edit: Boolean,
  },
  emits: ["reload-form", "update-form-read", "reload-form-read"],
  setup() {
    return {
      APP_ICONS,
      HEADER_IDS,
      dateFormat,
      boolToString,
      getStatusReportName,
      TABLE_ICONS,
      UserHelper,
      initCursor: {
        col: 0,
        row: 0,
      } as TableCellPointer,
      getRusDate: (date: string | Date) => dateFormat(date, "rusDate"),
      DEFAULT_LEFT_COLUMN_NAME,
    };
  },
  data() {
    return {
      tab: "" as "attrs" | string,
      exports: [] as ExportManagerInfo[],
      open: {
        info: true,
        nav: false,
        fillByAnalyticalReport: null as FillByAnalyticalReportProps | null,
        tableRemarks: null as TableRemarksProps | null,
        repositoryExtension: null as RepositoryExtensionProps | null,
        enterModal: null as EnterModalProps | null,
      },
      heights: {
        withoutTableContainer: 300,
      },
      formsTypesList: [] as ApiSelectValue[],
      usersDict: null as Dictionary<ApiVocUserDto> | null,
    };
  },
  async created() {
    window.addEventListener("resize", this.resizeHandler);
    this.tab = Object.keys(this.tables)[0];
    this.exports =
      (await ApiService.exportManagerRead(this.formRead.form_no)).json || [];
    this.formsTypesList = await ApiService.getSelectValues(
      "/api/repository/forms/types?action=list",
    ).then((x) => x.json.result);
    this.usersDict = await AppUsersService.getUsersDict();
  },
  mounted() {
    this.setHeights();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resizeHandler);
  },
  computed: {
    editTabButtons(): Dictionary<Function> {
      return {
        remark: () => {
          this.onOpenTableRemarksModal();
        },
      };
    },

    currentHelpValue(): string | undefined {
      return this.currentTableModelHelp?.modelDto.help || this.emptyHelp;
    },

    emptyHelp(): string | undefined {
      if (this.formRead?.edit.hint) {
        return "[не задано]";
      }

      return undefined;
    },

    currentTableModelHelp(): TableModel | undefined {
      if (!this.helpHelper.visible) {
        return undefined;
      }

      const context = this.helpHelper.getContext();
      if (!context) {
        return undefined;
      }

      return context.tableDataSet.getModel(context.field);
    },

    optionsExport(): DropdownOption[] {
      return this.exports.map((x) => {
        return {
          title: `${x.title}`,
          onClick: () => {
            AppApiService.exportManagerExportToFile({
              export_form_id: x.form_id,
              object_id: this.formRead.report_form_id,
              object_type: x.object_type,
            });
          },
          props: {
            class: "button-blue",
            style: { width: "100%" },
          },
        };
      });
    },
    table(): TableAppData | undefined {
      return this.tables[this.tab];
    },
  },
  watch: {
    "helpHelper.help"() {
      this.$forceUpdate();
    },
    "open.fillByAnalyticalReport"() {
      if (!this.open.fillByAnalyticalReport) {
        this.table?.tableDataSet.tableComponent?.focusTable();
      }
    },
  },
  methods: {
    resizeHandler() {
      this.setHeights();
    },
    setHeights() {
      this.heights.withoutTableContainer = (
        this.$refs.withoutTableContainer as HTMLDivElement
      ).clientHeight;
    },

    openFillByAnalyticalReport() {
      const { tableDataSet, dto } = this.table!;
      const pointer = tableDataSet.selected.cursor!;
      this.open.fillByAnalyticalReport = {
        pointer,
        tableDataSet,
        repositoryReadDto: this.formRepositoryRead,
        formReadDto: this.formRead,
      };
    },

    onOpenTableRemarksModal() {
      const { tableDataSet, dto } = this.table!;
      this.open.tableRemarks = {
        tableDataSet,
        repositoryReadDto: this.formRepositoryRead,
        formReadDto: this.formRead,
      };
    },

    onRouteRegReports() {
      const filter = reportHelpers.getInitFilterData();
      if (!filter.form_name) {
        filter.form_name = [];
      }

      filter.form_name.push({
        value: this.formRead.form_no,
        label: this.formRead.form_name,
      });

      if (this.report?.author_name) {
        if (!filter.author_name) {
          filter.author_name = [];
        }

        filter.author_name.push({
          value: this.report.author_id,
          label: this.report.author_name,
        });
      }

      this.onRouteRegReportsByFilter(filter);
    },

    onRouteRegReportsByForm(form_no: string, form_name: string) {
      const filter = reportHelpers.getInitFilterData();
      if (!filter.form_name) {
        filter.form_name = [];
      }

      filter.form_name.push({
        value: form_no,
        label: form_name,
      });
      this.onRouteRegReportsByFilter(filter);
    },

    onRouteRegReportsByFilter(filter: ReportFilter, target = "_blank") {
      window.open(getRouteLocationRegReportsByFilter(filter).href, target);
    },

    getSystemAttrsComponent(): InstanceType<typeof SystemAttrsComponent> {
      return this.$refs.systemAttrsRef as any;
    },

    async onSave() {
      try {
        const formReadData = this.getSystemAttrsComponent().formReadData;
        if (
          formReadData.form_period_start !== this.formRead.form_period_start ||
          formReadData.form_period_end !== this.formRead.form_period_end
        ) {
          await AppApiFormsService.formUpdate(
            this.formRead.report_form_id,
            formReadData,
          );
        }

        if (window.history.length) {
          await this.$router.back();
        } else {
          await this.$router.push("/reg-reports-v2");
        }
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При сохранении отчётной формы произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }
    },

    onChangeAttrs(attrs: Dictionary) {
      return this.attrsDataSet.repository.save(
        this.formRead,
        attrs,
        this.attrsDataSet,
      );
    },

    async onChangeAttrsByArray(
      event: Array<{ field: string; value: any; cancelChanges: () => {} }>,
    ) {
      const attrsDict = event.reduce((v, current) => {
        v[current.field] = current.value;
        return v;
      }, {} as Dictionary);
      const result = await this.onChangeAttrs(attrsDict);
      if (result.cancelChanges) {
        event.forEach((x) => x.cancelChanges());
      }
    },

    async onUpdateSystemAttrs(
      event: Array<{ field: string; value: any; cancelChanges: () => {} }>,
    ) {
      const attrsDict = event.reduce((v, current) => {
        v[current.field] = current.value;
        return v;
      }, {} as Dictionary);
      try {
        const result = await AppApiFormsService.formUpdate(
          this.formRead.report_form_id,
          attrsDict,
        );
        this.updateFormRead(result.json);
      } catch (e) {
        await ApiHelper.wrapNotifyError(e, { isError: true });
        event.forEach((x) => x.cancelChanges());
      }
    },

    onCloseEnterModal() {
      this.open.enterModal = null;
    },

    async onUpdateTableHint(
      tableDataSet: TableDataSet,
      model: TableModel,
      text: string,
    ) {
      try {
        await AppApiFormsService.changeHint(
          {
            form_no: this.formRead.form_no,
            table_name: tableDataSet.tableName,
            attr: model.field,
          },
          { text },
        );
        model.modelDto.help = text;
        this.helpHelper.setHelp(null);
      } catch (ex: any) {
        await ApiHelper.wrapNotifyError(ex, { isError: true });
        return false;
      }

      return true;
    },

    onOpenHelpEdit() {
      const context = this.helpHelper.getContext();
      if (!context) {
        return;
      }

      const model = this.currentTableModelHelp!;
      const title = `Редактирование подсказки для столбца "${model.caption}"`;
      this.open.enterModal = {
        title,
        modelValue: model.modelDto.help || "",
        onUpdateModelValue: async (value: string) => {
          if (
            await this.onUpdateTableHint(context.tableDataSet, model, value)
          ) {
            this.onCloseEnterModal();
          }
        },
        onClose: this.onCloseEnterModal,
      };
    },

    updateFormRead(formRead: FormReadDto) {
      this.$emit("update-form-read", formRead);
    },

    reloadFormRead(params: ReloadFormReadParams) {
      this.$emit("reload-form-read", params);
    },

    setHelp(pointer: Required<TableCellPointer> | null) {
      if (!pointer || !this.table) {
        this.helpHelper.setHelp(null);
        return;
      }

      const model = this.table.tableDataSet.getModel(pointer.col_name);
      if (!model) {
        return;
      }

      this.helpHelper.setHelp(model.modelDto.help || this.emptyHelp || null, {
        tableDataSet: this.table.tableDataSet,
        field: pointer.col_name,
      });
    },

    async onClickFormValidate(event: MouseEvent) {
      const buttonRef = event.target as HTMLButtonElement;
      buttonRef.disabled = true;
      try {
        await this.onFormValidate();
      } catch (e) {}

      buttonRef.disabled = false;
    },

    async onFormValidate() {
      let validateResult: Dictionary<FormInfoDataDto[]>;
      try {
        validateResult = (
          await ApiService.formValidate(this.formRead.report_form_id)
        ).json;
        this.reloadFormRead({ refreshInfo: true });
      } catch (e: any) {
        NotificationsService.send({
          type: "error",
          title: "При валидации отчётной формы произошла ошибка",
          text: await ApiHelper.getErrorMessage(e),
        });
        Logger.error({ e });
        return;
      }

      let count = 0;
      Object.keys(validateResult).forEach((tableName) => {
        const errors = validateResult[tableName];
        count += errors.length;
      });
      let table: TableAppData | undefined;
      if (
        this.table &&
        this.tab in validateResult &&
        validateResult[this.table.dto.tab_name]?.length > 0
      ) {
        table = this.table;
      } else {
        const findTableName = Object.keys(this.tables).find(
          (tableName) => validateResult[tableName]?.length > 0,
        );
        if (findTableName) {
          table = this.tables[findTableName];
        }
      }

      if (table) {
        this.tab = table.factory.options.tableName;
        table.tableDataSet.info.setTab("error");
      }

      const title = "Результат проверки отчётной формы";
      if (count === 0) {
        NotificationsService.send({
          type: "success",
          html: `
          <div style="display: flex; align-items: center;">
            <img src="${TABLE_ICONS.check.src}" alt="${TABLE_ICONS.check.alt}" style="margin-right: 5px; width: 24px;">
            <span>Ошибок не найдено</span>
          </div>
          `,
          title,
        });
      } else {
        NotificationsService.send({
          type: "error",
          html: `
          <div style="display: flex; align-items: center;">
            <img src="${TABLE_ICONS.error.src}" alt="${TABLE_ICONS.error.alt}" style="margin-right: 5px; width: 24px;">
            <span>Ошибок: ${count}</span>
          </div>
          `,
          title,
        });
      }
    },
    openContextMenuWithReportForm(event: MouseEvent, form: FormReadDto) {
      this.getReportFormContextMenuComponent().open(event, form);
    },

    openRepositoryExtension(repExt: RepositoryExtensionDto) {
      this.open.repositoryExtension = {
        repExt,
        formRead: this.formRead,
        tableDataSet: this.table!.tableDataSet,
      };
    },

    async onClickFormAction(formAction: ActionReportForms) {
      const table = this.table;
      const tableName = table ? this.tab : undefined;
      const tableDataSet = this.table?.tableDataSet;
      const selectedRowIndex: number | undefined =
        tableDataSet?.selected.cursor?.row;
      const context: ActionsExecReportFormsContext = {
        report_form_id: this.formRead.report_form_id,
        table_name: tableName,
        report_form: this.formRead,
      };

      if (tableDataSet) {
        const tableContext = tableDataSet.getContext();
        const selectedTableRow = tableDataSet.getRow(selectedRowIndex);
        const selected_row = selectedTableRow
          ? TableRow.getValue(selectedTableRow)
          : undefined;
        const selected_rows = tableContext.selected_rows
          .map((x) => {
            const tableRow = tableDataSet.getRow(x);
            return tableRow ? TableRow.getValue(tableRow) : undefined;
          })
          .filter(Boolean);
        Object.assign(context, {
          selected_rows,
          selected_row,
        });
      }

      try {
        const execResult = await ApiActionsService.executeExec(
          formAction,
          context,
          true,
        );

        const refresh = execResult.result?.refresh;
        this.reloadFormRead({
          refresh,
          selectedRowIndex,
          tableName,
        });
      } catch (e: any) {
        await ApiHelper.wrapNotifyError(e, { isError: true });
      }
    },

    getReportFormContextMenuComponent() {
      return this.$refs.reportFormContextMenu as InstanceType<
        typeof ReportFormContextMenu
      >;
    },
  },
});
