import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Teleport as _Teleport, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b360ee52"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  ref: "withoutTableContainer",
  class: "without-table-container default-page-padding-inline"
}
const _hoisted_2 = { class: "reporting-form__header" }
const _hoisted_3 = { class: "reporting-form__header__description" }
const _hoisted_4 = { style: {"display":"flex","justify-content":"space-between","width":"100%"} }
const _hoisted_5 = { style: {"display":"flex","flex-wrap":"wrap","width":"100%","gap":"10px"} }
const _hoisted_6 = { style: {"display":"flex","gap":"10px","justify-content":"flex-end"} }
const _hoisted_7 = ["data-status"]
const _hoisted_8 = { class: "tabs-container" }
const _hoisted_9 = ["data-active", "onClick"]
const _hoisted_10 = ["data-active"]
const _hoisted_11 = { class: "reporting-form__tab-content" }
const _hoisted_12 = {
  class: "table-scroll-container",
  draggable: "false"
}
const _hoisted_13 = {
  class: "default-page-padding-inline",
  style: {"overflow":"auto","height":"100%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CalculatorsModals = _resolveComponent("CalculatorsModals")!
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!
  const _component_HeaderSaveIndicator = _resolveComponent("HeaderSaveIndicator")!
  const _component_EditorFormsNavigation = _resolveComponent("EditorFormsNavigation")!
  const _component_ReportingFormToolbar = _resolveComponent("ReportingFormToolbar")!
  const _component_TableMultiHeaders = _resolveComponent("TableMultiHeaders")!
  const _component_CreateVocEntryButton = _resolveComponent("CreateVocEntryButton")!
  const _component_TableComponent = _resolveComponent("TableComponent")!
  const _component_ReportingFormBottom = _resolveComponent("ReportingFormBottom")!
  const _component_SystemAttrsComponent = _resolveComponent("SystemAttrsComponent")!
  const _component_ReportFormAttrs = _resolveComponent("ReportFormAttrs")!
  const _component_ReportFormContextMenu = _resolveComponent("ReportFormContextMenu")!
  const _component_FillByAnalyticalReportModal = _resolveComponent("FillByAnalyticalReportModal")!
  const _component_TableRemarksModal = _resolveComponent("TableRemarksModal")!
  const _component_RepositoryExtensionModal = _resolveComponent("RepositoryExtensionModal")!
  const _component_EditableHelpComponent = _resolveComponent("EditableHelpComponent")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.left
    }, [
      _createVNode(_component_CalculatorsModals, { type: "dropdown" }),
      (_ctx.report)
        ? (_openBlock(), _createBlock(_component_ButtonComponent, {
            key: 0,
            icon: _ctx.APP_ICONS.fileTree,
            class: "button-opacity",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open.nav = !_ctx.open.nav))
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createVNode(_component_ButtonComponent, {
        icon: _ctx.APP_ICONS.chatSquareAccent,
        class: "button-opacity",
        title: "Отобразить/скрыть блок примечаний, комментариев, ошибок",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.open.info = !_ctx.open.info))
      }, null, 8, ["icon"]),
      _createVNode(_component_HeaderSaveIndicator, {
        "table-data-set": _ctx.table?.tableDataSet
      }, null, 8, ["table-data-set"])
    ], 8, ["to"])),
    (_openBlock(), _createBlock(_Teleport, {
      to: '#' + _ctx.HEADER_IDS.bottom
    }, [
      _createVNode(_component_EditorFormsNavigation, {
        "current-report-form": _ctx.formRead,
        report: _ctx.report,
        visible: Boolean(_ctx.formRead && _ctx.open.nav && _ctx.report),
        onClose: _cache[2] || (_cache[2] = ($event: any) => (_ctx.open.nav = false))
      }, null, 8, ["current-report-form", "report", "visible"])
    ], 8, ["to"])),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.formRead.form_name), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.formRead.description), 1),
          _createElementVNode("div", _hoisted_6, [
            (_ctx.formRead)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  "data-status": _ctx.formRead.form_state,
                  class: "report-status-button"
                }, _toDisplayString(_ctx.getStatusReportName(_ctx.formRead.form_state)), 9, _hoisted_7))
              : _createCommentVNode("", true),
            _createVNode(_component_ButtonComponent, {
              icon: _ctx.TABLE_ICONS.dotsVertical,
              class: "button-opacity",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.openContextMenuWithReportForm($event, _ctx.formRead)))
            }, null, 8, ["icon"])
          ])
        ])
      ]),
      (_ctx.table?.tableDataSet.isFullScreenAndMounted)
        ? (_openBlock(), _createBlock(_Teleport, {
            key: 0,
            to: '#' + _ctx.table.tableDataSet.idContainerDivUp
          }, [
            _createVNode(_component_ReportingFormToolbar, {
              "form-actions": _ctx.formActions,
              "form-read": _ctx.formRead,
              "options-export": _ctx.optionsExport,
              "repository-extensions": _ctx.repositoryExtensions,
              table: _ctx.table,
              "onClick:formAction": _ctx.onClickFormAction,
              "onOpen:repositoryExtension": _ctx.openRepositoryExtension,
              "onOpen:fillByAnalyticalReport": _ctx.openFillByAnalyticalReport,
              "onClick:formValidate": _ctx.onClickFormValidate
            }, null, 8, ["form-actions", "form-read", "options-export", "repository-extensions", "table", "onClick:formAction", "onOpen:repositoryExtension", "onOpen:fillByAnalyticalReport", "onClick:formValidate"])
          ], 8, ["to"]))
        : (_openBlock(), _createBlock(_component_ReportingFormToolbar, {
            key: 1,
            "form-actions": _ctx.formActions,
            "form-read": _ctx.formRead,
            "options-export": _ctx.optionsExport,
            "repository-extensions": _ctx.repositoryExtensions,
            table: _ctx.table,
            "onClick:formAction": _ctx.onClickFormAction,
            "onOpen:repositoryExtension": _ctx.openRepositoryExtension,
            "onOpen:fillByAnalyticalReport": _ctx.openFillByAnalyticalReport,
            "onClick:formValidate": _ctx.onClickFormValidate
          }, null, 8, ["form-actions", "form-read", "options-export", "repository-extensions", "table", "onClick:formAction", "onOpen:repositoryExtension", "onOpen:fillByAnalyticalReport", "onClick:formValidate"])),
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (_table, name) => {
          return (_openBlock(), _createElementBlock("div", {
            key: name,
            "data-active": _ctx.boolToString(_ctx.tab === name),
            class: "tabs-container__tab",
            onClick: ($event: any) => (_ctx.tab = name)
          }, _toDisplayString(_table.dto.tab_name), 9, _hoisted_9))
        }), 128)),
        _createElementVNode("div", {
          "data-active": _ctx.boolToString(_ctx.tab === 'attrs'),
          class: "tabs-container__tab",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.tab = 'attrs'))
        }, " Атрибуты ", 8, _hoisted_10)
      ])
    ], 512),
    _createElementVNode("div", _hoisted_11, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tables, (_table) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: _table.factory.options.tableName,
          class: "table-scroll-container",
          style: {"height":"100%"}
        }, [
          _createElementVNode("div", _hoisted_12, [
            _createVNode(_component_TableComponent, {
              autofocus: true,
              "init-cursor": _ctx.initCursor,
              isShowInfo: false,
              isViewColumnNumber: true,
              "table-data-set": _table.tableDataSet,
              "onUpdate:cells": ($event: any) => (_table.tableDataSet.updateCells($event)),
              onRowAppend: ($event: any) => (_table.tableDataSet.rowAppend()),
              onRowInsert: ($event: any) => (_table.tableDataSet.rowInsert($event)),
              onRowDelete: ($event: any) => (_table.tableDataSet.rowDelete($event)),
              onRowDuplicate: ($event: any) => (_table.tableDataSet.rowDuplicate($event)),
              onCellsClear: ($event: any) => (_table.tableDataSet.cellsClear($event)),
              onRowDown: ($event: any) => (_table.tableDataSet.rowMoveDown($event)),
              onRowUp: ($event: any) => (_table.tableDataSet.rowMoveUp($event)),
              "onUpdate:cursor": _cache[5] || (_cache[5] = ($event: any) => (_ctx.setHelp($event)))
            }, {
              headers: _withCtx(({ classes, tableDataSet }) => [
                _createVNode(_component_TableMultiHeaders, {
                  classes: classes,
                  headers: _table.factory.options.headers,
                  "left-col": _ctx.DEFAULT_LEFT_COLUMN_NAME,
                  model: _table.factory.modelDtoSort,
                  "table-data-set": tableDataSet,
                  onClickLeftCol: ($event: any) => (tableDataSet.selectedAll(this.$nextTick))
                }, null, 8, ["classes", "headers", "left-col", "model", "table-data-set", "onClickLeftCol"])
              ]),
              "voc-header-toolbar-start": _withCtx((payload) => [
                _createVNode(_component_CreateVocEntryButton, {
                  voc_type: payload.model.vocDataSet.type.voc_type,
                  onSuccess: ($event: any) => (payload.onUpdateVoc())
                }, null, 8, ["voc_type", "onSuccess"])
              ]),
              _: 2
            }, 1032, ["init-cursor", "table-data-set", "onUpdate:cells", "onRowAppend", "onRowInsert", "onRowDelete", "onRowDuplicate", "onCellsClear", "onRowDown", "onRowUp"])
          ]),
          _createVNode(_component_ReportingFormBottom, {
            "edit-tab-buttons": _ctx.editTabButtons,
            formActions: _ctx.formActions,
            "help-helper": _ctx.helpHelper,
            "open-info": _ctx.open.info,
            "table-data-set": _table.tableDataSet,
            onClose: _cache[6] || (_cache[6] = ($event: any) => (_ctx.open.info = false)),
            "onClick:formAction": _ctx.onClickFormAction
          }, null, 8, ["edit-tab-buttons", "formActions", "help-helper", "open-info", "table-data-set", "onClick:formAction"])
        ])), [
          [_vShow, _table.factory.options.tableName === _ctx.tab]
        ])
      }), 128)),
      _withDirectives(_createElementVNode("div", _hoisted_13, [
        (_ctx.usersDict)
          ? (_openBlock(), _createBlock(_component_SystemAttrsComponent, {
              key: 0,
              ref: "systemAttrsRef",
              "change-attrs-debounce": 500,
              edit: _ctx.edit,
              "form-read": _ctx.formRead,
              "form-repository-read": _ctx.formRepositoryRead,
              "users-dict": _ctx.usersDict,
              style: {"padding-top":"10px","padding-left":"2px","padding-right":"5px"},
              "onUpdate:fields": _ctx.onUpdateSystemAttrs
            }, null, 8, ["edit", "form-read", "form-repository-read", "users-dict", "onUpdate:fields"]))
          : _createCommentVNode("", true),
        _createVNode(_component_ReportFormAttrs, {
          ref: "attrsRef",
          "attrs-data-set": _ctx.attrsDataSet,
          "change-attrs-debounce": 500,
          edit: _ctx.edit,
          "form-read": _ctx.formRead,
          helpHelper: _ctx.helpHelper,
          types: _ctx.formRepositoryRead.types,
          style: {"margin-top":"30px","padding-left":"2px","padding-right":"5px"},
          "onChange:attrs": _ctx.onChangeAttrsByArray
        }, null, 8, ["attrs-data-set", "edit", "form-read", "helpHelper", "types", "onChange:attrs"])
      ], 512), [
        [_vShow, _ctx.tab === 'attrs']
      ])
    ]),
    _createVNode(_component_ReportFormContextMenu, {
      ref: "reportFormContextMenu",
      onSuccessDeleteForm: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.replace('/'))),
      onUpdateFormRead: _ctx.updateFormRead,
      onCloseEnterModal: _cache[8] || (_cache[8] = ($event: any) => (_ctx.open.enterModal = null)),
      onOpenEnterModal: _cache[9] || (_cache[9] = ($event: any) => (_ctx.open.enterModal = $event))
    }, null, 8, ["onUpdateFormRead"]),
    (_ctx.open.fillByAnalyticalReport)
      ? (_openBlock(), _createBlock(_component_FillByAnalyticalReportModal, {
          key: 0,
          "props-object": _ctx.open.fillByAnalyticalReport,
          onClose: _cache[10] || (_cache[10] = ($event: any) => (_ctx.open.fillByAnalyticalReport = null))
        }, null, 8, ["props-object"]))
      : _createCommentVNode("", true),
    (_ctx.open.tableRemarks)
      ? (_openBlock(), _createBlock(_component_TableRemarksModal, {
          key: 1,
          "props-object": _ctx.open.tableRemarks,
          onClose: _cache[11] || (_cache[11] = ($event: any) => (_ctx.open.tableRemarks = null))
        }, null, 8, ["props-object"]))
      : _createCommentVNode("", true),
    (_ctx.open.repositoryExtension)
      ? (_openBlock(), _createBlock(_component_RepositoryExtensionModal, {
          key: 2,
          "props-object": _ctx.open.repositoryExtension,
          onClose: _cache[12] || (_cache[12] = ($event: any) => (_ctx.open.repositoryExtension = null)),
          onReloadForm: _cache[13] || (_cache[13] = ($event: any) => (_ctx.$emit('reload-form')))
        }, null, 8, ["props-object"]))
      : _createCommentVNode("", true),
    (
      _ctx.table && _ctx.currentTableModelHelp && (_ctx.currentHelpValue || _ctx.formRead.edit.hint)
    )
      ? (_openBlock(), _createBlock(_component_EditableHelpComponent, {
          key: 3,
          editable: _ctx.formRead.edit.hint,
          modelValue: _ctx.currentHelpValue,
          title: _ctx.currentTableModelHelp.caption,
          style: {"z-index":"4"},
          onClose: _cache[14] || (_cache[14] = ($event: any) => (_ctx.helpHelper.setHelp(null))),
          onClickEdit: _ctx.onOpenHelpEdit
        }, null, 8, ["editable", "modelValue", "title", "onClickEdit"]))
      : _createCommentVNode("", true),
    (_ctx.open.enterModal)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 4,
          "model-value": _ctx.open.enterModal.modelValue,
          readonly: _ctx.open.enterModal.readonly,
          title: _ctx.open.enterModal.title,
          "title-save-button": _ctx.open.enterModal.titleSaveButton,
          onClose: _ctx.open.enterModal.onClose,
          "onUpdate:modelValue": _ctx.open.enterModal.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "title-save-button", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 64))
}