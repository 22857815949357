import { ApiService, FormReadDto, HttpService, NotificationsService } from "table";
import {
  FormReadDtoExtended,
  ReportDto,
  SaveFormDto,
  SaveReportDto,
} from "@/modules/registration-reports/services/ApiServiceRegReports/types";
import { sleep } from "@/common/helpers/sleep";
import { ApiListWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ApiSelectValue } from "table/dist/services/Api/types/ApiSelectValue";

class ApiServiceRegReports {
  getQueryParams = ApiService.getQueryParams;

  getReportList() {
    return HttpService.get<ApiListWrapper<ReportDto>>(
      "/api/reports?action=list",
    );
  }

  getReport(report_id: number) {
    return HttpService.get<ReportDto>(
      "/api/reports?" +
        this.getQueryParams({
          action: "read",
          report_id,
        }),
    );
  }

  getFormListByFilter(report_id: number | string) {
    return HttpService.get<ApiListWrapper<FormReadDto>>(
      "/api/forms?" +
        this.getQueryParams({
          action: "filter",
          report_id,
        }),
    );
  }
  getFormList() {
    return HttpService.get<ApiListWrapper<FormReadDtoExtended>>(
      "/api/forms?" +
        this.getQueryParams({
          action: "list",
        }),
    );
  }

  getRepositoryFormTypesList() {
    const url = "/api/repository/form_types?action=list";
    return ApiService.useCache(url, () =>
      HttpService.get<ApiListWrapper<ApiSelectValue>>(url),
    );
  }

  getRepositoryReportsTypesList() {
    const url = "/api/repository/reports/types?action=list";
    return ApiService.useCache(url, () =>
      HttpService.get<ApiListWrapper<ApiSelectValue>>(url),
    );
  }

  saveReport(data: SaveReportDto) {
    return HttpService.post<ReportDto>("/api/reports?action=save", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  saveForm(body: SaveFormDto[]) {
    return HttpService.post<ApiListWrapper<FormReadDtoExtended>>(
      "/api/forms?action=save",
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      },
    );
  }

  deleteForm(form_id: string | number) {
    return HttpService.post(
      "/api/forms?" +
        this.getQueryParams({
          action: "delete",
          form_id,
        }),
    );
  }

  deleteReport(report_id: string | number) {
    return HttpService.post<null>(
      "/api/reports?" +
        this.getQueryParams({
          action: "delete",
          report_id,
        }),
    );
  }

  async reportUnload(report_id: string[] | string) {
    window.open(
      "/api/reports?" +
        this.getQueryParams({
          action: "unload",
          report_id,
        }),
      "_blank",
    );
    // заглушка для ожидания выгрузки
    return await sleep(1000);
  }

  async formUnload(form_id: string[] | string) {
    window.open(
      "/api/forms?" +
        this.getQueryParams({
          action: "unload",
          form_id,
        }),
      "_blank",
    );
    // заглушка для ожидания выгрузки
    return await sleep(1000);
  }
  formUnloadCpFile(form_id: string[] | string) {
    window.open(
      "/api/forms?" +
        this.getQueryParams({
          action: "unload_cp_file",
          form_id,
        }),
      "_blank",
    );
  }

  uploadReport(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return HttpService.post("/api/forms?action=upload", { body: formData });
  }

  async formsCreateAdjustment(report_form_ids: Array<number>) {
    const result = await HttpService.post<ApiListWrapper<number>>(
      `/api/reports?action=create_adjustment_report`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          report_form_ids,
        }),
      },
    );
    NotificationsService.send({
      title: "Создание корректировки для отчётной формы",
      type: "success",
      text: "ОК",
    });
    result.json.result.forEach((id) => {
      window.open(`/editor-forms/${id}`, "_blank");
    });
    return result;
  }
}

export default new ApiServiceRegReports();
