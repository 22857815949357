import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CreateVocEntryButton = _resolveComponent("CreateVocEntryButton")!
  const _component_AttrsComponent = _resolveComponent("AttrsComponent")!
  const _component_EditableHelpComponent = _resolveComponent("EditableHelpComponent")!
  const _component_EnterModalComponent = _resolveComponent("EnterModalComponent")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([this.class, "report-form-attrs"]),
    style: _normalizeStyle(_ctx.style)
  }, [
    _createVNode(_component_AttrsComponent, {
      "change-attrs-debounce": _ctx.changeAttrsDebounce,
      "custom-help": true,
      data: _ctx.attrsDataSet.data,
      "empty-help": _ctx.emptyHelp,
      "global-handlers": _ctx.GLOBAL_ATTRS_HANDLERS,
      "loading-object": _ctx.attrsDataSet.repository.loading,
      "models-dto": _ctx.attrsDataSet.modelsDto,
      "read-only": _ctx.edit === false ? true : !_ctx.attrsDataSet.edit.attrs,
      types: _ctx.types,
      "unique-id": _ctx.attrsDataSet.uniqueId,
      onReset: _ctx.onReset,
      onChangeField: _ctx.onChangeField,
      onClickHelp: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.currentHint = $event;
        _ctx.helpHelper.setVisible(true);
      })
    }, {
      "voc-header-toolbar-start": _withCtx((payload) => [
        _createVNode(_component_CreateVocEntryButton, {
          voc_type: payload.model.vocDataSet.type.voc_type,
          onSuccess: ($event: any) => (payload.onUpdateVoc())
        }, null, 8, ["voc_type", "onSuccess"])
      ]),
      _: 1
    }, 8, ["change-attrs-debounce", "data", "empty-help", "global-handlers", "loading-object", "models-dto", "read-only", "types", "unique-id", "onReset", "onChangeField"]),
    (
        _ctx.helpHelper.visible &&
        _ctx.currentHint &&
        (_ctx.currentHelpValue || _ctx.attrsDataSet.edit.hint)
      )
      ? (_openBlock(), _createBlock(_component_EditableHelpComponent, {
          key: 0,
          editable: _ctx.attrsDataSet.edit.hint,
          "model-value": _ctx.currentHelpValue,
          title: _ctx.currentHint.model.caption,
          style: {"z-index":"4"},
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.currentHint = null)),
          onClickEdit: _ctx.onOpenEditHelp
        }, null, 8, ["editable", "model-value", "title", "onClickEdit"]))
      : _createCommentVNode("", true),
    (_ctx.enterModalProps)
      ? (_openBlock(), _createBlock(_component_EnterModalComponent, {
          key: 1,
          "model-value": _ctx.enterModalProps.modelValue,
          readonly: _ctx.enterModalProps.readonly,
          title: _ctx.enterModalProps.title,
          onClose: _ctx.enterModalProps.onClose,
          "onUpdate:modelValue": _ctx.enterModalProps.onUpdateModelValue
        }, null, 8, ["model-value", "readonly", "title", "onClose", "onUpdate:modelValue"]))
      : _createCommentVNode("", true)
  ], 6))
}