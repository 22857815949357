
import { defineComponent } from "vue";
import {
  ApiHelper,
  ContextMenuOption,
  ContextMenuService,
  EnterModalProps,
  FormReadDto,
  Logger,
  StatusReportingForm,
  TABLE_ICONS,
  VOC_NAMES_DICT,
} from "table";
import ApiServiceRegReports from "@/modules/registration-reports/services/ApiServiceRegReports/ApiServiceRegReports";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import EditingVocModal, {
  EditingVocModalMode,
} from "@/modules/editing-voc/components/EditingVocModal.vue";
import EditingVocExtendedModal from "@/modules/editing-voc/components/EditingVocExtendedModal.vue";
import { VocExtraInfo } from "@/modules/editing-voc/common/types";
import AppApiService from "@/services/AppApiService/AppApiService";
import EditingVocEntry from "@/modules/editing-voc/components/EditingVocEntry.vue";

// Создание корректирующей формы доступно только с этими статусами
export const CREATE_CORRECT_FORM_ALLOW_STATUS: StatusReportingForm[] = [
  "unloaded",
  "loaded",
  "rejected",
  "accepted",
];

export default defineComponent({
  name: "ReportFormContextMenu",
  components: {
    EditingVocEntry,
    EditingVocModal,
    EditingVocExtendedModal,
  },
  props: {},
  emits: [
    // форма успешно удалена
    "success-delete-form",
    // форма корректировки успешно создана
    "success-create-correct-form",
    "open-enter-modal",
    "close-enter-modal",
    "update-form-read",
  ],
  setup() {
    return {
      VOC_NAMES_DICT,
    };
  },
  data() {
    return {
      show: {
        vocEntryModal: null as {
          record_id?: number | string;
          mode: EditingVocModalMode;
          voc_type: string;
          vocExtraInfo: VocExtraInfo;
        } | null,
      },
    };
  },
  methods: {
    async openVocEdit(record_id: number | string, voc_type: string) {
      this.show.vocEntryModal = {
        record_id,
        mode: "edit",
        voc_type,
        vocExtraInfo: await AppApiService.getVocExtraInfo(voc_type),
      };
    },

    async onCreateCorrectForm({ form }: { form: FormReadDto }) {
      try {
        await ApiServiceRegReports.formsCreateAdjustment([form.report_form_id]);
      } catch (e: any) {
        await ApiHelper.wrapNotifyError(e, {
          isError: true,
          title:
            "Произошла ошибка при создании корректировки для отчётной формы",
        });
        Logger.error(e);
      }
    },
    async onDeleteForm({ form }: { form: FormReadDto }) {
      const result = (
        await ApiServiceRegReports.deleteForm(form.report_form_id)
      ).json;
      if (!result) {
        this.$emit("success-delete-form", { form });
      }
    },

    async onOpenEditCommentReportForm(formRead: FormReadDto) {
      this.onOpenEnterModal({
        title: "Редактирование комментария к отчётной форме",
        readonly: !formRead.edit.comment,
        modelValue: formRead.form_comment?.text || "",
        onClose: () => {
          this.$emit("close-enter-modal");
        },
        onUpdateModelValue: async (text) => {
          await this.onUpdateFormRead(formRead.report_form_id, {
            form_comment: { text },
          });
          this.$emit("close-enter-modal");
        },
      });
    },

    onOpenEnterModal(enterModalProps: EnterModalProps) {
      this.$emit("open-enter-modal", enterModalProps);
    },

    onUpdateFormRead(report_form_id: number, formRead: Partial<FormReadDto>) {
      return ApiHelper.wrapNotifyError(
        async () => {
          const result = await AppApiFormsService.formUpdate(
            report_form_id,
            formRead,
          );
          this.onEmitUpdateFormRead(result.json);
        },
        { title: "Сохранение отчётной формы" },
      );
    },

    onEmitUpdateFormRead(formRead: FormReadDto) {
      this.$emit("update-form-read", formRead);
    },

    open(event: any, form: FormReadDto) {
      let options: ContextMenuOption[] = [
        CREATE_CORRECT_FORM_ALLOW_STATUS.some((x) => x === form.form_state)
          ? {
              text: "Корректировка",
              icon: TABLE_ICONS.cogOutlineBlue,
              click: () => this.onCreateCorrectForm({ form }),
            }
          : undefined,
        form.author_id
          ? {
              text: "Данные организации",
              icon: TABLE_ICONS.newspaperOutlineBlue,
              click: () => {
                this.openVocEdit(form.author_id!, VOC_NAMES_DICT.organizations);
              },
            }
          : undefined,
        {
          text: "Редактировать комментарий",
          icon: TABLE_ICONS.chatSquareBlue,
          click: () => this.onOpenEditCommentReportForm(form),
          disabled: !form.edit?.comment,
        },
        {
          text: "Удалить",
          icon: TABLE_ICONS.trashCanOutline,
          click: () => this.onDeleteForm({ form }),
        },
      ].filter(Boolean) as ContextMenuOption[];
      ContextMenuService.send({
        event,
        options,
      });
    },
  },
});
