
import { defineComponent, PropType } from "vue";
import {
  ActionReportForms,
  ReportFormActionsDict,
} from "@/services/ApiActions/types";
import { TableAppData } from "@/common/types/TableAppData";
import ActionsButtons from "@/components/ActionButton/ActionsButtons.vue";
import {
  ButtonComponent,
  DropdownComponent,
  FormReadDto,
  PanelFilters,
  TableActionsComponent,
} from "table";
import { DropdownOption } from "table/dist/components/Dropdown/common/types/DropdownOption";
import { RepositoryExtensionDto } from "@/services/AppApiRepositoryService/types/RepositoryExtensionDto";
import { DEFAULT_DROPDOWN_PROPS } from "@/common/consts";

export default defineComponent({
  name: "ReportingFormToolbar",
  components: {
    ButtonComponent,
    ActionsButtons,
    DropdownComponent,
    TableActionsComponent,
    PanelFilters,
  },
  props: {
    formRead: {
      type: Object as PropType<FormReadDto>,
      required: true,
    },
    table: {
      type: Object as PropType<TableAppData>,
      required: false,
    },
    optionsExport: {
      type: Array as PropType<DropdownOption[]>,
      default: () => [],
    },
    repositoryExtensions: {
      type: Object as PropType<RepositoryExtensionDto[] | null>,
      required: true,
    },
    formActions: {
      type: Object as PropType<ReportFormActionsDict>,
    },
  },
  emits: [
    "click:form-action",
    "click:form-validate",
    "open:repository-extension",
    "open:fill-by-analytical-report",
  ],
  setup() {
    return {
      DEFAULT_DROPDOWN_PROPS,
    };
  },
  data() {
    return {};
  },
  computed: {
    repositoryExtensionsFilterComputed(): RepositoryExtensionDto[] {
      return (
        this.repositoryExtensions?.filter((x) =>
          x.forms.includes(this.formRead.form_no),
        ) || []
      );
    },
    fillBySnk(): boolean {
      if (!this.formRead) {
        return false;
      }
      return ["form_1_1", "form_1_2", "form_1_3", "form_1_4", "form_1_5"].some(
        (x) => x === this.formRead.form_no,
      );
    },
  },
  watch: {},
  created() {},
  methods: {
    onClickFormAction(formAction: ActionReportForms) {
      this.$emit("click:form-action", formAction);
    },

    openRepositoryExtension(repExt: RepositoryExtensionDto) {
      this.$emit("open:repository-extension", repExt);
    },

    openFillByAnalyticalReport() {
      this.$emit("open:fill-by-analytical-report");
    },

    onClickFormValidate(event: any) {
      this.$emit("click:form-validate", event);
    },
  },
});
