
import { defineComponent, PropType } from "vue";
import {
  ApiHelper,
  AttrsComponent,
  copy,
  EditableHelpComponent,
  EnterModalComponent,
  EnterModalProps,
  HelpHelper,
  RepositoryReadModelDto,
} from "table";
import { AttrsDataSet } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDataSet";
import { Dictionary } from "table/dist/types/Dictionary";
import { RepositoryReadTypeDto } from "table/dist/services/Api/types/RepositoryReadDto";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import { FormReadDto } from "table/dist/services/Api/types/FormReadDto";
import CreateVocEntryButton from "@/components/smart/CreateVocEntryButton.vue";
import { GLOBAL_ATTRS_HANDLERS } from "@/common/consts";

export default defineComponent({
  name: "ReportFormAttrs",
  components: {
    EnterModalComponent,
    EditableHelpComponent,
    CreateVocEntryButton,
    AttrsComponent,
  },
  props: {
    style: {
      required: false,
    },
    class: {
      required: false,
    },
    attrsDataSet: {
      type: Object as PropType<AttrsDataSet>,
      required: true,
    },
    types: {
      type: Object as PropType<Dictionary<RepositoryReadTypeDto>>,
      required: true,
    },
    changeAttrsDebounce: {
      type: Number,
      default: 0,
    },
    formRead: {
      type: Object as PropType<FormReadDto>,
      required: true,
    },
    helpHelper: {
      type: HelpHelper,
      required: true,
    },
    edit: {
      type: Boolean,
      required: false,
    },
  },
  emits: [
    "change:attrs", // изменилось значение аттрибутов,
    "change:help", // изменилась помощь
  ],
  setup() {
    return {
      GLOBAL_ATTRS_HANDLERS,
    };
  },
  data() {
    return {
      currentHint: null as null | {
        model: RepositoryReadModelDto;
        help: string | undefined;
      },
      enterModalProps: null as null | EnterModalProps,
    };
  },
  watch: {},
  computed: {
    currentHelpValue(): string | undefined {
      return this.currentHint?.help || this.emptyHelp;
    },

    emptyHelp() {
      if (this.attrsDataSet.edit.hint) {
        return "[не заполнено]";
      }

      return undefined;
    },
  },
  methods: {
    onChangeField({
      model,
      value,
    }: {
      model: RepositoryReadModelDto;
      value: any;
    }) {
      const prevValue = copy(this.attrsDataSet.data[model.field]);
      const cancelChanges = () => {
        this.attrsDataSet.setDataField(model.field, prevValue);
      };

      this.attrsDataSet.setDataField(model.field, value);
      this.$emit("change:attrs", [
        {
          field: model.field,
          value: value,
          cancelChanges,
        },
      ]);
    },

    onReset(model: RepositoryReadModelDto) {
      this.onChangeField({
        model,
        value: copy(this.attrsDataSet.initData[model.field]),
      });
    },

    async onSaveHint(model: RepositoryReadModelDto, text: string) {
      try {
        await AppApiFormsService.changeHint(
          {
            form_no: this.formRead.form_no,
            attr: model.field,
          },
          { text },
        );
        model.help = text;
        this.currentHint = null;
        return true;
      } catch (ex) {
        await ApiHelper.wrapNotifyError(ex, { isError: true });
        return false;
      }
    },

    onCloseEditHelp() {
      this.enterModalProps = null;
    },

    onOpenEditHelp() {
      if (!this.currentHint) {
        return;
      }

      const { model } = this.currentHint;

      this.enterModalProps = {
        title: `Редактирование помощи для атрибута "${model.caption}"`,
        modelValue: model.help,
        onUpdateModelValue: async (text) => {
          if (await this.onSaveHint(model, text)) {
            this.onCloseEditHelp();
          }
        },
        onClose: this.onCloseEditHelp,
      };
    },
  },
});
