import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e67f7fd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "table-info-tab-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ButtonComponent = _resolveComponent("ButtonComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ButtonComponent, {
      icon: _ctx.TABLE_ICONS.pencilOutline,
      "icon-style": _ctx.iconStyle,
      class: "button-opacity",
      onClick: _ctx.onOpen
    }, null, 8, ["icon", "icon-style", "onClick"]),
    _createVNode(_component_ButtonComponent, {
      icon: _ctx.TABLE_ICONS.trashCanOutline,
      "icon-style": _ctx.iconStyle,
      class: "button-opacity",
      onClick: _ctx.onRemove
    }, null, 8, ["icon", "icon-style", "onClick"])
  ]))
}