import { Dictionary } from "table/dist/types/Dictionary";
import { AttrsDefaultRepository } from "@/modules/editor-forms/components/ReportFormAttrs/classes/AttrsDefaultRepository";
import { copy, FormReadDto, RepositoryReadModelDto } from "table";

export interface AttrsDataSetEdit {
  attrs?: boolean;
  hint?: boolean;
}

export class AttrsDataSet {
  public initData: Dictionary;
  public edit: AttrsDataSetEdit = {};

  constructor(
    public uniqueId: string,
    public repository: AttrsDefaultRepository,
    public modelsDto: RepositoryReadModelDto[],
    public data: Dictionary,
  ) {
    this.initData = copy(data);
  }

  setEdit(edit?: AttrsDataSetEdit) {
    this.edit = edit || {};
  }

  setEditByFormRead(formRead: FormReadDto) {
    this.setEdit({
      attrs: formRead.edit.edit,
      hint: formRead.edit.hint,
    });
  }

  setDataField(field: string, value: any) {
    this.data[field] = value;
  }
}
