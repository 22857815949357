import { ApiListUniversalWrapper } from "table/dist/services/Api/types/ApiListWrapper";
import { ReportFormActionsDict } from "@/services/ApiActions/types";
import { ApiPaginationParams } from "table/dist/services/Api/types/ApiPaginationParams";
import { FormExecParams } from "@/services/AppApiFormsService/types/FormExecParams";
import { ApiService, FormReadDto, HttpService } from "table";
import { FormsExtraInfo } from "@/services/AppApiFormsService/types/FormsExtraInfo";
import { JournalRequiredFieldsDto } from "@/services/AppApiRepositoryService/types/JournalRequiredFieldsDto";

class AppApiFormsService {
  getQueryParams = ApiService.getQueryParams;

  exec(params: FormExecParams) {
    return HttpService.post("/api/forms?action=exec", {
      body: JSON.stringify(params),
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  formUpdate(report_form_id: string | number, data: Partial<FormReadDto>) {
    return HttpService.post<FormReadDto>(
      `/api/forms?action=update&form_id=${report_form_id}`,
      {
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }

  extraInfo() {
    const url = "/api/forms?action=get_extra_info";
    return HttpService.get<FormsExtraInfo>(url);
  }

  getListForJournal<T = JournalRequiredFieldsDto>(
    journal_name: string,
    preset?: string,
    paginationParams?: ApiPaginationParams,
    extract?: string[],
  ) {
    return HttpService.get<ApiListUniversalWrapper<T>>(
      "/api/forms?" +
        this.getQueryParams({
          action: "list_for_journal",
          journal_name,
          preset,
          extract,
          ...paginationParams,
        }),
    );
  }

  getActions(report_form_id: number) {
    return HttpService.get<ReportFormActionsDict>(
      `/api/forms/actions?` +
        this.getQueryParams({
          action: "list",
          report_form_id,
        }),
    );
  }

  changeHint(
    queryParams: { form_no: string; attr: string; table_name?: string },
    body: { text: string },
  ) {
    return HttpService.post<FormReadDto>(
      `/api/forms?` +
        this.getQueryParams({
          action: "change_hint",
          ...queryParams,
        }),
      {
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }
}

export default new AppApiFormsService();
