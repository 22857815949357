
import { defineComponent, PropType } from "vue";
import {
  AttrsComponent,
  ButtonComponent,
  Dictionary,
  FormReadDto,
  ModalComponent,
  RepositoryReadModelDto,
  TableDataSet,
  TableModel,
} from "table";
import { FormExecParams } from "@/services/AppApiFormsService/types/FormExecParams";
import { RepositoryExtensionDto } from "@/services/AppApiRepositoryService/types/RepositoryExtensionDto";
import AppApiFormsService from "@/services/AppApiFormsService/AppApiFormsService";
import { GLOBAL_ATTRS_HANDLERS } from "@/common/consts";

export interface RepositoryExtensionProps {
  repExt: RepositoryExtensionDto;
  formRead: FormReadDto;
  tableDataSet: TableDataSet;
}

export default defineComponent({
  name: "RepositoryExtensionModal",
  components: {
    ModalComponent,
    AttrsComponent,
    ButtonComponent,
  },
  props: {
    propsObject: {
      type: Object as PropType<RepositoryExtensionProps>,
      required: true,
    },
  },
  emits: ["close", "reload-form"],
  setup() {
    return {
      GLOBAL_ATTRS_HANDLERS,
    };
  },
  data() {
    return {
      currentData: {} as Dictionary,
    };
  },
  computed: {},
  watch: {},
  created() {},
  methods: {
    close() {
      this.$emit("close");
    },

    async onClickExecute() {
      let row = this.propsObject.tableDataSet.selected.cursor?.row;
      await AppApiFormsService.exec({
        method: this.propsObject.repExt.method,
        row: row === undefined ? null : row,
        report_form_id: this.propsObject.formRead.report_form_id,
        ...this.currentData,
      } as FormExecParams);
      this.$emit("reload-form");
    },

    onChangeField(event: { model: RepositoryReadModelDto; value: any }) {
      this.currentData[event.model.field] = event.value;
    },

    onResetField(model: TableModel) {
      const type = this.propsObject.tableDataSet.getTypeModel(model);
      this.currentData[model.field] = type?.default || null;
    },
  },
});
