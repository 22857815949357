
import { defineComponent, PropType } from "vue";
import { Dictionary, FormInfoDataDto, TABLE_ICONS, TableDataSet } from "table";

export default defineComponent({
  name: "TableInfoTabButtons",
  components: {},
  props: {
    tableDataSet: {
      type: Object as PropType<TableDataSet>,
      required: true,
    },
    editTabButtons: {
      type: Object as PropType<Dictionary<Function>>,
    },
    tab: {
      type: String,
      required: true,
    },
    value: {
      type: Object as PropType<FormInfoDataDto>,
      required: true,
    },
  },
  emits: ["remove"],
  setup() {
    return {
      TABLE_ICONS,
    };
  },
  data() {
    return {};
  },
  computed: {
    isEdit() {
      return this.tableDataSet.getEdit(this.tab);
    },
    iconStyle() {
      return {
        width: "18px",
        opacity: this.isEdit ? 1 : 0.5,
      };
    },
  },
  watch: {},
  created() {},
  methods: {
    onRemove() {
      if (!this.isEdit) {
        return;
      }

      this.$emit("remove", this.tab);
    },

    onOpen() {
      if (!this.isEdit) {
        return;
      }

      this.tableDataSet.setOpenByAssign({
        [this.tab]: { pointer: this.value.pointer },
      });
    },
  },
});
